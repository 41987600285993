<template>
  <div class="logo">
    <v-avatar class="profile" tile v-if="school.logoUrl" :size="size">
       <v-img contain :src="school.logoUrl" />
    </v-avatar>
     <v-avatar v-else :color="'#' + randomColor" :size="size">
      <span class="white--text text-h5">{{
        school.name.charAt(0).toUpperCase()
      }}</span>
    </v-avatar>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: ["school", "size"],
  data: () => ({
    color: [
      "000080",
      "008080",
      "800080",
      "008000",
      "808000",
      "800000",
      "808080",
      "0000FF",
      "00FFFF",
    ],
    randomColor: null,
  }),
  created() {
    //
  },
  mounted() {
    this.randomColor =
      this.color[Math.floor(Math.random() * this.color.length)];
  },
  methods: {
    //
  },
};
</script>
<style>
.logo {
  display: inline-block !important;
}
</style>