<template>
  <v-row justify="center">
    <v-btn v-if="!auth.token" @click.stop="dialog = true" text color="primary" class="view-event text-right">
    {{buttonText}}
      <v-icon right dark> mdi-lock-outline </v-icon>
    </v-btn>
        <v-btn v-else-if="auth.token" :to="{ name: 'PageMainFestivalRead', params: { festivalCode: festivalCode } }"  text color="primary" class="view-event text-right">
       {{buttonText}}
    </v-btn>
    <v-dialog v-model="dialog" max-width="290">
      <v-form ref="form" lazy-validation @submit.prevent="validate">
        <v-card :loading="api.isLoading">
          <v-card-title class="text-h6">
            {{$t("view.PageMainFestival.password_title")}}
            <v-spacer></v-spacer>
            <v-btn  icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <FormInput :callbackSelect="callbckSelect" :festivalCode="festivalCode" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text :to="{ name: 'PageMainFestivalRead', params: { festivalCode: festivalCode }, query: {s: formCode} }">
              {{$t("action.confirm")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import FormInput from "@/components/Festival/SchoolCode/FormInput";
 import { mapState } from 'vuex'
export default {
  computed: mapState({
      auth: state => state.auth.data,
    }),
  data() {
    return {
      dialog: false,
      formCode: "",
      //BOC:[api]
      api: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      //EOC
    };
  },
  props: ["festivalCode", "callbackSuccess","buttonText"],
  components: {
    FormInput,
  },
  methods: {
    callbckSelect(code) {
      this.formCode = code;
    },
  },
  mounted(){
    this.formCode = this.code
  }
};
</script>

<style>
.view-event::before {
  background-color: transparent !important;
}
</style>