<template>
  <v-container>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <v-card
      v-if="!api.isError"
      :loading="api.isLoading"
      elevation="2"
      class="mx-auto"
      outlined
    >
      <v-card-title>{{ $t("model.name.festivals") }}</v-card-title>
      <v-simple-table fixed-header height="520px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t("model.prop.no") }}</th>
              <th class="text-left">{{ $t("model.name.festival") }}</th>
              <th class="text-left">{{ $t("model.name.schools") }}</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody class="table" v-if="!api.isLoading">
            <tr class="text-center" v-if="data.length == 0">
              <td colspan="4" class="grey--text">
                {{ $t("api.response.no_data") }}
              </td>
            </tr>
            <tr v-for="(item, index) in data" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                {{ item.name }} <span class="text--secondary">({{item.subtitle}})</span>
              </td>
              <td v-if="item.FestivalSchool && item.FestivalSchool.length > 1">
                <span
                  v-for="school in item.FestivalSchool.slice(0, 5)"
                  :key="school.School.code"
                  class="pr-1 avatar"
                >
                  <SchoolLogo class="logo" :size="36" :school="school.School" />
                </span>
                 <v-avatar v-if="item.FestivalSchool.length-5>0"  size="36" color='black'>
                    <span class="white--text">+{{item.FestivalSchool.length-5}}</span>
                  </v-avatar>
              </td>
              <td v-else>
                    -
              </td>
              <td>
                <SchoolCode
                  :festivalCode="item.code"
                  :buttonText="buttonText"
                />
              </td>
            </tr>
          </tbody>
          <div v-if="api.isLoading">
            <ASkeletonLoader type="table-tbody" width="100%" />
          </div>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import SchoolCode from "@/components/Festival/SchoolCode/Dialog";
import SchoolLogo from "@/components/Festival/SchoolLogo.vue";
import { mapState } from "vuex";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  components: {
    SchoolCode,
    SchoolLogo,
  },
  data: () => ({
    buttonText: "",
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
    };
    //EOC
  },

  methods: {
    fetch() {
      this.api.url =
        this.$api.servers.classroom + "/v1/" + this.$_getLocale() + "/festival";
      this.$api.fetch(this.api);
    },
  },
  mounted() {
    this.fetch();
    this.buttonText = this.$t("action.view");
  },
};
</script>

<style>
.view-event::before {
  background-color: transparent !important;
}
.v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}
.v-data-table__wrapper {
  -ms-overflow-style: none;
}
/* .logo{
   position: absolute;
  
}
.avatar{
   position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
} */
</style>